/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
const HomePageTemplate = ({ data }) => {
  const { title, subTitle, featuredImage, body } = data.directus.homePage
  return (
    <main className="Home">
      <PageHeader
        large
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      />

      <section className="section">
        <div className="container">
          <Content body={body} />
        </div>
      </section>
    </main>
  )
}

// Export Default HomePage for front-end
const HomePage = ({ data }) => {
  return (
    <Layout meta={data.directus.homePage.SEO || false}>
      <HomePageTemplate data={data} />
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file

  query HomePage {
    directus {
      homePage {
        title
        subTitle
        featuredImage {
          id
        }
        body
        SEO {
          image {
            id
          }
          title
          description
        }
      }
    }
  }
`
